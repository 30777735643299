import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../../css/Table.css";
import axios from "axios";
const supprimer = require("../images/supprimer.png");
const modifier = require("../images/update.png");

const AjoutStructureSanitaire = () => {
  const [structure, setStructure] = useState([]);
  const [newStructure, setNewStructure] = useState({
    valeur: "",
    niveau: "",
    description: "",
  });
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  function toggleModal() {
    setIsOpenAdd(!isOpenAdd);
  }
  function toggleModalEdit() {
    setIsOpenEdit(!isOpenEdit);
  }
  function openEdit(current) {
    setEditedUser(current);
    toggleModalEdit();
  }
  useEffect(() => {
    fetchStructures();
  }, []);

  const handleUpdateUser = async (updatedUser) => {
    try {
      const response = await axios.put(
        `https://precvadithies.sn/update-sante/${updatedUser.id}`,
        updatedUser,
      );
      const updatedUserData = response.data;
      setIsOpenEdit(!isOpenEdit);
      console.log(updatedUserData);
      fetchStructures();
      // setUsers(users.map(user => user.id === parseInt (updatedUserData.id ) ? updatedUserData : user));
      setEditedUser(null);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://precvadithies.sn/delete-sante/${userId}`);
      setStructure(structure.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  const fetchStructures = async () => {
    try {
      const response = await fetch("https://precvadithies.sn/list-all-sante");
      const data = await response.json();
      console.log(data);
      setStructure(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleCreateStructure = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://precvadithies.sn/create-sante",
        newStructure,
      );
      console.log(response.data);
      fetchStructures();
      //   const createdUser = response.data;
      //   setUsers([...users, createdUser])
      setNewStructure({ valeur: " ", description: "", niveau: "" });
      setIsOpenAdd(!isOpenAdd);
      //   setIsSubmitted(true);
      // Handle successful login, e.g., store token in local storage or state
    } catch (error) {
      console.error(error);
      //   setErrorMessages({ name: "uname", message: errors.uname });`
      // Handle error, e.g., display error message to the user
    }
  };

  return (
    <div className="tableWrap">
      <div>
        {/* <AddUser/> */}
        <button style={{ backgroundColor: "#195329" }} onClick={toggleModal}>
          Ajouter une structure sanitaire
        </button>
        <Modal
          isOpen={isOpenAdd}
          onRequestClose={toggleModal}
          contentLabel="Ajouter Structure "
          className="mymodal"
          ariaHideApp={false}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <form className="addForm" onSubmit={handleCreateStructure}>
            <div id="form-group">
              <label id="name-label">Nom Structure</label>
              <input
                required
                type="text"
                name="valeur"
                placeholder="Entrez le nom de la structure"
                value={newStructure.valeur}
                onChange={(e) =>
                  setNewStructure({ ...newStructure, valeur: e.target.value })
                }
              />
            </div>
            <div id="form-group">
              <label id="name-label"> Description Structure </label>
              <input
                required
                type="text"
                name="description"
                placeholder="Description structure"
                value={newStructure.description}
                onChange={(e) =>
                  setNewStructure({
                    ...newStructure,
                    description: e.target.value,
                  })
                }
              />
            </div>
            {/* <input   type="text"  name="roleName" placeholder="role" value={newUser.roleName} onChange={(e) => setNewUser({ ...newUser, roleName: e.target.value })}/> */}
            <div id="form-group">
              <label id="name-label"> Niveau structure </label>
              <select
                required
                value={newStructure.niveau}
                onChange={(e) =>
                  setNewStructure({ ...newStructure, niveau: e.target.value })
                }
              >
                <option value="" hidden>
                  Selectionnez un niveau
                </option>
                <option value="poste">Poste de Santé</option>
                <option value="hopital">Hopital</option>
                <option value="pharmacie">Pharmacie</option>
              </select>
            </div>
            <div id="form-group">
              <button type="submit">Ajouter</button>
              <button className="deletebutton" onClick={toggleModal}>
                Annuler
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={isOpenEdit}
          onRequestClose={toggleModalEdit}
          contentLabel="Ajouter Utilisateur "
          className="mymodal"
          ariaHideApp={false}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          {editedUser && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateUser(editedUser);
              }}
            >
              <div id="form-group">
                <label id="name-label"> Valeur </label>
                <input
                  required
                  type="text"
                  value={editedUser.valeur}
                  onChange={(e) =>
                    setEditedUser({ ...editedUser, valeur: e.target.value })
                  }
                />
              </div>
              <div id="form-group">
                <label id="name-label"> Description </label>
                <input
                  required
                  type="text"
                  value={editedUser.description}
                  onChange={(e) =>
                    setEditedUser({
                      ...editedUser,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div id="form-group">
                <label id="name-label"> Role </label>
                <select
                  required
                  value={editedUser.niveau}
                  onChange={(e) =>
                    setEditedUser({ ...editedUser, niveau: e.target.value })
                  }
                >
                  <option value="poste">Poste de Santé</option>
                  <option value="hopital">Hopital</option>
                  <option value="pharmacie">Pharmacie</option>
                </select>
              </div>
              <div id="form-group">
                <button type="submit">Update</button>
                <button className="deletebutton" type="cancel">
                  Annuler
                </button>
              </div>
            </form>
          )}
        </Modal>

        <table>
          <thead>
            <tr>
              <th> Id</th>
              <th> Nom ecole</th>
              <th> Description Ecole </th>
              <th> Niveau</th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {structure.map((current) => (
              <tr key={current.id}>
                <td> {current.id}</td>
                <td>{current.valeur}</td>
                <td>{current.description}</td>
                <td>
                  {current.niveau === "poste"
                    ? "Poste de Santé"
                    : current.niveau === "hopital"
                      ? "Hopital"
                      : "Pharmacie"}{" "}
                </td>
                <td>
                  <button
                    className="editUser"
                    onClick={() => openEdit(current)}
                  >
                    <img height={18} width={20} alt="" src={modifier} />
                  </button>

                  <button
                    onClick={() => handleDeleteUser(current.id)}
                    className="deleteUser"
                  >
                    <img height={18} width={20} alt="" src={supprimer} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AjoutStructureSanitaire;
