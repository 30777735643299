import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import "../../css/contact.css";
import NavBar from "../NavBar";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hqe2gte",
        "template_7la4f7o",
        form.current,
        "ryVdz0_ZsjTl5Yxg-",
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(" message sent ");
        },
        (error) => {
          console.log(error.text);
          console.log(" message unsent ");
        },
      );
  };

  return (
    <>
      <NavBar />
      <div className="containersearch">
        <div>
          <h5 className="igt">
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>

        {/* <div className="div3"> */}
        {/* <input type="text" placeholder="Search.."></input> */}
        {/* </div> */}
      </div>
      <div className="contact">
        <div className="contact3 py-5">
          <div className="row no-gutters">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card-shadow">
                    <img
                      alt="contact"
                      src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/2.jpg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-box ml-3">
                    <h1 className="h1contact">Contactez-Nous</h1>
                    <form className="mt-4" ref={form} onSubmit={sendEmail}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="name"
                              name="user_name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="email address"
                              name="user_email"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="phone"
                              name="phone"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <textarea
                              className="form-control"
                              rows="3"
                              placeholder="message"
                              name="message"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-danger-gradiant mt-3 text-white border-0 px-3 py-2 "
                          >
                            <span> ENVOYER</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="card mt-4 border-0 mb-4">
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <div className="card-body d-flex align-items-center c-detail pl-0">
                          <div className="mr-3 align-self-center">
                            <img
                              alt="contact"
                              src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon1.png"
                            />
                          </div>
                          <div className="">
                            <h6 className="font-weight-medium">Addresse</h6>
                            <p className="">
                              Dakar, Avenue .....
                              <br /> Rue ....
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="card-body d-flex align-items-center c-detail">
                          <div className="mr-3 align-self-center">
                            <img
                              alt="contact"
                              src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon2.png"
                            />
                          </div>
                          <div className="">
                            <h6 className="font-weight-medium">Téléphone</h6>
                            <p className="">
                              +221 000 00 00
                              <br /> +221 000 00 00
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="card-body d-flex align-items-center c-detail">
                          <div className="mr-3 align-self-center">
                            <img
                              alt="contact"
                              src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon3.png"
                            />
                          </div>
                          <div className="">
                            <h6 className="font-weight-medium">Email</h6>
                            <p className="">
                              info@precvadi.com
                              <br /> support@precvadi.com
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
