import React from "react";
import { Carousel } from "react-bootstrap";
import '../../css/MonCarousel.css';

function MonCarrousel() {
 
  return (
  

  <Carousel className='monstyle1' interval={5000} pause={false} ride="carousel" controls={false}>
  <Carousel.Item className='monstyle2'>
    <div className="monstyle3">
    Thiadiaye fait partie du département de Mbour, une subdivision de la
            région de Thiès
    </div>
  </Carousel.Item>
  <Carousel.Item className='monstyle2'>
    <div className="monstyle3"> 
    Cette plateforme met ensemble des données clés et informations
            essentielles sur les différents axes prioritaires de développement au
            niveau de la commune de Boulel.
    </div>
  </Carousel.Item>
  <Carousel.Item className='monstyle2'>
    <div className="monstyle3">
    Ce système de données fédère les besoins en données des acteurs clés
            intervenant dans le développement communal.
    </div>
  </Carousel.Item>
  <Carousel.Item className='monstyle2'>
    <div className="monstyle3">
    Il est possible que cette phase initiale englobe des données
            concernant la santé et l'éducation, tout en gardant à l'esprit la
            possibilité d'extension à d'autres domaines à l'avenir
    </div>
  </Carousel.Item>
  </Carousel>
  );
}

export default MonCarrousel;
