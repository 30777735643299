import React from "react";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
import NavBarConnexion from "../NavBarConnexion";
const imageVisualisation =
  require("../images/fichierPhotoVisualisation.PNG").default;
// import { useParams } from "react-router-dom";

const Registrer = () => {
  return (
    <div>
      <NavBarConnexion />
      <div className="containersearch">
        <div>
          <h5 className="igt">
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>

        {/* <div className="div3"> */}
        {/* <input type="text" placeholder="Search.."></input> */}
        {/* </div> */}
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
          marginTop: "20px",
        }}
      >
        <div>
          <img src={imageVisualisation} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Registrer;
