import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
//import NavDropdown from "react-bootstrap/NavDropdown";
// import Footer from "./Footer";
import "./NavbarConnexion.css";
import { UserContext } from "../../src/UserContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
// import { NavDropdown } from "react-bootstrap";
// const logomontrolland = require("./images/logomontrolland.jpeg") ;
const loginuser = require("./images/userlogin.png");
const logomontrolland = require("./images/thiadiaye1.png");
function NavBarConnexion() {
  const { user, logoutUser } = useContext(UserContext);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
      <div className="logo">
          <NavLink exact to="/">
           {/* <FontAwesomeIcon icon={faAlignJustify} /> */}
            <img className="nav-logo" title="THIADIAYE" src={logomontrolland} alt="logo thiadiaye"/>
            {/* <p style={{fontSize: `1.2rem`, fontWeight: `bold`}}>BOULEL</p>  */}
          </NavLink>
          </div>
        <div className="nav-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/admin"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Accueil
              </NavLink>
            </li>
            {user.roleId === "1" ? (
              <li className="nav-item">
                <NavLink
                  exact
                  to="/gesdata"
                  activeClassName="active"
                  className="nav-links fw-bold"
                  onClick={handleClick}
                >
                  {/* {user.roleId} */}
                  Données
                </NavLink>
              </li>
            ) : /*user.roleId*/ localStorage.getItem("role") === "3" ? (
              <li className="nav-item">
                <NavLink
                  exact
                  to="/gesdata"
                  activeClassName="active"
                  className="nav-links fw-bold"
                  onClick={handleClick}
                >
                  {/* {user.roleId} */}
                  Données
                </NavLink>
              </li>
            ) : (
              ""
            )}

            <li className="nav-item">
              <NavLink
                exact
                to="/gesressource"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Ressources
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/gestionblog"
                activeClassName="active"
                className="nav-links fw-bold"
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>

            {
              /*user.roleId*/ localStorage.getItem("role") === "1" ? (
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/gesuser"
                    activeClassName="active"
                    className="nav-links fw-bold"
                    onClick={handleClick}
                  >
                    Utilisateurs
                  </NavLink>
                </li>
              ) : (
                ""
              )
            }
            <li className="nav-item d-flex justify-content-center align-items-center gap-2">
              <span > {localStorage.getItem('username')} </span>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links connection fw-bold"
                onClick={logoutUser}
              >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
              <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
            </svg>
            {/*user.username*/} 
              </NavLink>
            </li>

            {/* <li className="nav-item">
              
               <NavDropdown title ={user.username}> 
                <NavDropdown.Item   onClick={()=>logoutUser}>  Deconnecter </NavDropdown.Item>
               </NavDropdown>
              
            </li> */}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBarConnexion;
