import React, { useState, useEffect } from "react";
import "../../css/Actualite.css";
import NavBar from "../NavBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/GestionBlog.css";
import "../../css/horizontal.css";

import { Container, Card } from "react-bootstrap";
const gif = require("../images/updload.gif");

const Actualite = () => {
  return (
    <>
      <NavBar />
      <Container fluid>
        <PostAct />
      </Container>
    </>
  );
};

export default Actualite;

const PostAct = () => {
  useEffect(() => {
    fetchBlog();
  }, []);
  const [blog, setBlog] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleVoirPlusClick = (myblog) => {
    setSelectedCard(myblog);
  };
  const fetchBlog = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://precvadithies.sn/list-blog");
      const data = await response.json();
      console.log(data);
      setBlog(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  return (
    <div className="table-container">
      <div style={{ overflowX: "auto", display: "flex", marginTop: "80px" }}>
        {blog.map((myblog, index) => (
          <div key={index} style={{ flex: "0 0 auto", marginRight: "10px" }}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            >
              <Card.Img
                variant="top"
                width={200}
                height={200}
                src={"https://www.precvadithies.sn/" + myblog.image_name}
              />
              <Card.Body>
                <Card.Title>{myblog.description}</Card.Title>
                <Card.Text style={{ color: "black" }}>{myblog.des}</Card.Text>
                <div className="container bg-light"></div>
                <div className="col-md-12 text-center">
                  <button
                    style={{ backgroundColor: "#258BAF" }}
                    type="button"
                    className="btn btn-success"
                    onClick={() => handleVoirPlusClick(myblog)}
                  >
                    Voir plus
                  </button>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      <div>
        {selectedCard && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Card style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Card.Img
                  variant="top"
                  width={60}
                  height={250}
                  src={
                    "https://www.precvadithies.sn/" + selectedCard.image_name
                  }
                />
              </div>
              <Card.Body>
                <Card.Title>{selectedCard.description}</Card.Title>
                <Card.Text style={{ color: "black" }}>
                  {selectedCard.des}
                </Card.Text>
                <div className="container bg-light"></div>
              </Card.Body>
            </Card>
            {/* Add more information as needed */}
          </div>
        )}
      </div>
      {loading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <img src={gif} alt="Loading" width="50" height="50" />
        </div>
      )}
    </div>
  );
};

// const  Actualites = () => {
//   return (
//     <>
//         <NavBar/>
// 		<Container fluid >

// 			<Row className="roomfac fontReg">
// 				<Col md={8} className="mb-4">
// 					<Post1 />
// 				</Col>

// 			</Row>

// 		</Container>
//         </>

//   );
// };
