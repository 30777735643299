import React from "react";
import NavBarConnexion from "../NavBarConnexion";
import Home from "../Pages/Home";
import Footer from "../Footer";




const GestionHome = () => {

  /* const [colorIndex, setColorIndex] = useState(0);
  const colors = ['red', 'blue', 'green', 'orange'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []); */

  return (
    <>
      <NavBarConnexion />
      <div className="containersearch">
        <div>
          <h5 className="igt" /* style={{ color: colors[colorIndex] }} */>
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>
      </div>
      
      <Home />
      <Footer />
    </>
  );
};

export default GestionHome;
