import React from "react";
import "../../css/Acteur.css";
// import ReactPlayer from "react-player";
import { Container } from "react-bootstrap";
import { Row, Col, Image } from "react-bootstrap";
import NavBar from "../NavBar";

const aphrclogo = require("../images/aphrc.png").default;
const ucad = require("../images/ucad.png").default;
const ensa = require("../images/ensa.jpg").default;
const boulel = require("../images/Localisation boule ok.png").default;
const cardthiadiaye =
  require("../images/Localisation Thiadiaye ok.png").default;
const montrolland =
  require("../images/Localisation Mont Rolland (2).png").default;

const Presentation = () => {
  return (
    <Container className="shadow-lg p-3 mb-5 bg-body rounded mt-5">
      <Row sm={12} className=" px-4 my-5">
        <div>
          <h1>Présentation des differents acteurs du projet</h1>
          <p className="para">
            La plupart des pays d'Afrique ont progressé au cours des dernières
            décennies vers la réalisation des ODD.Une grande partie des
            processus de suivi des progrès vers la réalisation des objectifs de
            développement durable se sont jusqu'à présent concentrés sur le
            renforcement des systèmes statistiques nationaux qui fournissent des
            données pour la prise de décision au niveau national. Les niveaux
            infranationaux de gouvernance sont des couches importantes à prendre
            en compte dans la réalisation des objectifs de développement au
            niveau national, en particulier en Afrique.C'est dans ce contexte
            que l'APHRC{" "}
            <span>
              {" "}
              <Image
                width={20}
                height={20}
                roundedCircle
                alt="171x180"
                src={aphrclogo}
              />
            </span>{" "}
            met en œuvre un projet visant à renforcer les systèmes de données
            nationaux et infranationaux afin de répondre à la demande et à
            l'offre de données dans certaines unités infranationales. <br></br>
            PRECVADI est un projet qui porte sur le RENFORCEMENT DES CHAÎNES DE
            VALEUR DES DONNÉES INFRANATIONALES EN AFRIQUE SUBSAHARIENNE.
            <br></br>
            Ce projet a été coordonné par les structures suivantes : IGT
            Institut National de la gouvernance territoriale un institut de
            l'UCAD{" "}
            <span>
              {" "}
              <Image
                width={20}
                height={20}
                roundedCircle
                alt="171x180"
                src={ucad}
              />
            </span>{" "}
            et l'ENSA Ecole National Superieure d'Agriculture{" "}
            <span>
              {" "}
              <Image
                width={20}
                height={20}
                roundedCircle
                alt="171x180"
                src={ensa}
              />
            </span>{" "}
          </p>
        </div>
        {/* <h2>
        Présentation de l’Institut de Gouvernance territoriale et De
        Developpement local{" "}
      </h2> */}

        {/* <Col sm={12}>
        <p className="para">
          L’Institut de la gouvernance territoriale (IGT) a été créé en
          2016 par l’Université Cheikh Anta Diop de Dakar. ds
        </p>
      </Col> */}
      </Row>
    </Container>
  );
};

const THIADIAYE = () => {
  return (
    <div>
      <Container className="shadow-lg p-3 mb-5 bg-body rounded mt-5">
        <Row sm={12}>
          <div className="jumbotron text-center">
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <h2>
                Thiadiaye
                <span>
                  {" "}
                  {/* <Image
              width={50}
              height={40}
              roundedCircle
              alt="171x180"
              src={aphrclogo}
            /> */}
                </span>
              </h2>
            </div>
          </div>
          {/* <h2>
      Présentation de l’Institut de Gouvernance territoriale et De
      Developpement local{" "}
    </h2> */}

          <Col sm={6}>
            <p className="para">
              De part sa position géographique avantageuse, la commune de Mont
              Rolland (dans la région de Thiès), située dans les contreforts des
              collines du plateau du Cayor dans sa partie ouest, non loin de
              l'océan Atlantique, offre un décor divers caractérisé par un
              relief accidenté. Délimitée au Sud-ouest par le département de
              Tivaouane, elle s'étend sur 172 km2. Elle est limitée au Nord par
              la commune de Notto-Gouye Diama, au Sud par celle de Fandène, à
              l'Est par Chérif Lô et à l'Ouest par les collectivités
              territoriales de Djender et de Keur Mousseu.{" "}
              <Row>
                {/* <Col sm={12} className="mt-5">
                  <Image
                    width={"100%"}
                    height={"100%"}
                    alt="171x180"
                    src={ThiadiayeEducation}
                  />
                </Col> */}
                {/* <Col sm={12} className="mt-5">
                  <Image
                    width={"100%"}
                    height={"100%"}
                    alt="171x180"
                    src={ThiadiayeSante}
                  />
                </Col> */}
              </Row>
            </p>
          </Col>
          <Col sm={6}>
            <Image
              width={"90%"}
              height={"100%"}
              alt="171x180"
              src={cardthiadiaye}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const BOULEL = () => {
  return (
    <div>
      <Container className="shadow-lg p-3 mb-5 bg-body rounded mt-5">
        <Row sm={12}>
          <div className="jumbotron text-center">
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <h2>
                BOULEL
                <span>
                  {" "}
                  {/* <Image
              width={50}
              height={40}
              roundedCircle
              alt="171x180"
              src={aphrclogo}
            /> */}
                </span>
              </h2>
            </div>
          </div>
          {/* <h2>
      Présentation de l’Institut de Gouvernance territoriale et De
      Developpement local{" "}
    </h2> */}

          <Col sm={6}>
            <p className="para">
              De part sa position géographique avantageuse, la commune de Mont
              Rolland (dans la région de Thiès), située dans les contreforts des
              collines du plateau du Cayor dans sa partie ouest, non loin de
              l'océan Atlantique, offre un décor divers caractérisé par un
              relief accidenté. Délimitée au Sud-ouest par le département de
              Tivaouane, elle s'étend sur 172 km2. Elle est limitée au Nord par
              la commune de Notto-Gouye Diama, au Sud par celle de Fandène, à
              l'Est par Chérif Lô et à l'Ouest par les collectivités
              territoriales de Djender et de Keur Mousseu.{" "}
              <Row>
                {/* <Col sm={12} className="mt-5">
                  <Image
                    width={"100%"}
                    height={"100%"}
                    alt="171x180"
                    src={boulelEducation}
                  />
                </Col> */}
                {/* <Col sm={12} className="mt-5">
                  <Image
                    width={"100%"}
                    height={"100%"}
                    alt="171x180"
                    src={boulelSante}
                  />
                </Col> */}
              </Row>
            </p>
          </Col>
          <Col sm={6}>
            <Image width={"90%"} height={"100%"} alt="171x180" src={boulel} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const MONTROLLAND = () => {
  return (
    <div>
      <Container className="shadow-lg p-3 mb-5 bg-body rounded mt-5">
        <Row sm={12}>
          <div className="jumbotron text-center">
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <h2>
                MONT ROLLAND
                <span>
                  {" "}
                  {/* <Image
              width={50}
              height={40}
              roundedCircle
              alt="171x180"
              src={aphrclogo}
            /> */}
                </span>
              </h2>
            </div>
          </div>
          {/* <h2>
      Présentation de l’Institut de Gouvernance territoriale et De
      Developpement local{" "}
    </h2> */}

          <Col sm={6}>
            <p className="para">
              De part sa position géographique avantageuse, la commune de Mont
              Rolland (dans la région de Thiès), située dans les contreforts des
              collines du plateau du Cayor dans sa partie ouest, non loin de
              l'océan Atlantique, offre un décor divers caractérisé par un
              relief accidenté. Délimitée au Sud-ouest par le département de
              Tivaouane, elle s'étend sur 172 km2. Elle est limitée au Nord par
              la commune de Notto-Gouye Diama, au Sud par celle de Fandène, à
              l'Est par Chérif Lô et à l'Ouest par les collectivités
              territoriales de Djender et de Keur Mousseu.{" "}
              <Row>
                {/* <Col sm={12} className="mt-5">
                  <Image
                    width={"100%"}
                    height={"100%"}
                    alt="171x180"
                    src={montRollandEducation}
                  />
                </Col> */}
                {/* <Col sm={12} className="mt-5">
                  <Image
                    width={"100%"}
                    height={"100%"}
                    alt="171x180"
                    src={montRollandSante}
                  />
                </Col> */}
              </Row>
            </p>
          </Col>
          <Col sm={6}>
            <Image
              width={"80%"}
              height={"80%"}
              alt="171x180"
              src={montrolland}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const Acteur = () => {
  return (
    <div>
      <NavBar />
      <div className="containersearch">
        <div>
          <h5 className="igt">
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>
      </div>
      <Presentation />
      <div
        style={{
          display: "flow",
          margin: "auto",
          width: "50%",
          height: "100%",
        }}
      ></div>
      <BOULEL />
      <MONTROLLAND />
      <THIADIAYE />
    </div>
  );
};
