import React, { useState } from "react";
import "../../css/home.css";
/* import "../../css/button.css"; */
// import { MapContainer, TileLayer, useMap,Marker,Popup } from 'leaflet'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "react-bootstrap";
import Newsletter from "./Newsletter";
import MonCarrousel from "./MonCarrousel";
import Itableindicateur from "./Itableindicateur";
import Itableindicateur_2 from "./Itableindicateur_2";
import S_Itableindicateur from "./S_Itableindicateur";
import S_Itableindicateur_2 from "./S_Itableindicateur_2";
import Partenaires from './NosPartenaires';
// import Maps from "./Maps";
// import MapsSante from "./MapsSante";
import Modal from "react-modal";

// import { Container } from "react-bootstrap";
// import { Row, Col} from "react-bootstrap";

// const dashboard = require("../images/imageDashbord.jpeg") ;
const fermerModal = require("../images/fermerModal.png");
const educicon = require("../images/educicon.png");
const santeicon = require("../images/educsante.png");
const tableicon = require("../images/itable.png");
// const dashboard1 = require("../images/fichierPhotoVisualisation.PNG") ;

export const Home = () => {
  //FOR MODAL TABLE BOU BAKH BI
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('structure');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [isEducation, SetEducation] = React.useState(true);
  const [isSante, SetSanté] = React.useState(false);
  const HandleEducation = () => {
    SetEducation(true);
    SetSanté(false);
    console.log("test education");
  };
  const HandleSante = () => {
    SetSanté(true);
    SetEducation(false);
    console.log("test sante ");
  };

  const CommuneDashboard = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="2000"
          height="650"
          //DASHBOARD SANTE BOULEL
          src="https://app.powerbi.com/view?r=eyJrIjoiNDJlOWE2M2ItYjAyNC00ZGE1LTkwMmUtOTYxYjNjZTNmZTE4IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9  "
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };

  const TableauBordEducation = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="2000"
          height="650"
          src="https://app.powerbi.com/view?r=eyJrIjoiMzgzYzk4ZTMtODk1OS00MDVkLWE4YTUtYmZhMTY5ZGI5NTM1IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          // src="https://app.powerbi.com/view?r=eyJrIjoiODA5NjUwOGQtYjZkNi00YmNjLTg0ZTItN2E5NGY5M2E1Mzg0IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };
 /* const TableauBordSante = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <iframe
          title="DashboardIGT"
          width="2000"
          height="650"
          //DASHBOARD SANTE BOULEL
          src="https://app.powerbi.com/view?r=eyJrIjoiOTUwMmNmODgtMzRjNy00YTQ2LTk5M2QtZjVlMjg2ZWQ4ZjliIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  };
*/
  return (
    <div>
      <div className="main">
        {/* <div className="heading"> */}

        <div className="carouselcontain mb-5">
        <div className="cardDomain">
            <div>
              {/* <h2 style={{color:"white",textDecoration:"underline"}}  > Commune de Boulel </h2>  */}
              <p className='carous'>
                {" "}
               {/* Cette plateforme met ensemble des données clés et informations
                essentielles sur les différents axes prioritaires de
                développement au niveau de la commune de Boulel. Ce système de
                données fédère les besoins en données des acteurs clés
                intervenant dans le développement communal. Il s’agira pour
                cette phase de données concernant la santé, l’éducation.*/}
          <MonCarrousel />
                
              </p>
            </div>
          </div>
          <div className="carouseldiv">
            {/* <img
                alt="precvadi"
                src={thiadiaye}
                className="carouselimg"
                fluid
                rounded
              /> 
            <DemoCarousel />*/}
            <iframe title="homePageThiadiaye" style={{height:`100%`, width:`100%`}} 
            src="https://app.powerbi.com/view?r=eyJrIjoiNDZlMzAxY2EtMzViMi00MGUxLTg0YzQtZDlmZjU3YThjYzliIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" 
            frameborder="0" allowFullScreen="true"></iframe>
            
          </div>
          
        </div>

        <div className='w-100 d-flex justify-content-center align-items-center'>
            <div className='spaced-div commune'>
              <CommuneDashboard />
            </div>
          </div> 

        
          {/* <Container className="shadow-lg p-1 mb-1 bg-body ">
            <Row sm={12} className=" px-4 my-5">    
            <Col sm={12}>  */}
         
          {/* <div className="button">  
          <div className="button11" > </div>
         <div className="button1"  > <button className="buttondomain" style={{ backgroundColor: isEducation ? "#316685" : "#7b9bad" }}  type="button"  onClick={HandleEducation}>Education</button> </div>
         <div className="button2"  > <button className="buttondomain" type="button" style={{ backgroundColor: isSante ? "#316685" : "#7b9bad" }}  onClick={HandleSante}>Santé</button> </div>
         <div className="button12" >  </div>
          </div>  */}
          {/* {!isEducation && <TableauBordEducation/> } */}
        

        <div className="communeinfo">
        <div className="spaced-div arrow1">
            <h1 className='gros-titre'>Domaine</h1>
          </div>
          <div className="cardrowdomain">
          {/* <div className="card">
              <Button
                className='btnIcon'
                style={{
                  backgroundColor: isEducation ? "#316685" : "#7b9bad",
                }}
                onClick={HandleEducation}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Education</h5>
              </Button>
            </div>
           <div className="card">
              <Button
              className='btnIcon'
                style={{
                  backgroundColor: isSante ? "#316685" : "#7b9bad",
                }}
                type="button"
                onClick={HandleSante}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Sante</h5>
              </Button>
              </div>

              <div className="card">
              <Button
              className='btnIcon'
                style={{
                  backgroundColor:"#289642",
                }}
                type="button"
                onClick={openModal}
              >
                <img
                  alt="precvadi"
                  src={tableicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES'>Tableau</h5>
              </Button>
            </div>*/}
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  position: "relative",
                  top: "55%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                  padding: "10px",
                },
              }}
            >
              <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginBottom: "5px",
          borderBottom: activeTab === 'structure' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('structure')}
      >
        Données par structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 'communal' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('communal')}
      >
        Données communales
      </span>

      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginBottom: "5px",
          marginLeft: "10px",
          borderBottom: activeTab === 's_structure' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('s_structure')}
      >
        Santé structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 's_communal' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('s_communal')}
      >
        Santé commune
      </span>

      
              <div>
               
                {activeTab == "structure" ? <Itableindicateur /> :
            activeTab == "communal" ? <Itableindicateur_2 />:
            activeTab == "s_structure" ? <S_Itableindicateur /> :
            activeTab == "s_communal" && <S_Itableindicateur_2 />
          }
              </div>

              <img
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={closeModal}
                alt="precvadi"
                src={fermerModal}
                title="fermer"
                fluid
                rounded
                width="30px"
                heigth="30px"
              />
            </Modal>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="spaced-div commune">
            {/* {isEducation && <Maps/> }
         { isSante && <MapsSante/>} */}
           <TableauBordEducation />
          
          </div>
          </div>
        </div>
        <div style={{width:`100%`, display:`flex`, justifyContent:`center`, alignItems:`center`, marginBottom:`5rem`, marginTop:`5rem`}}>
          <div className='spaced-div' style={{width:`91.5%`, display:`flex`, justifyContent:`center`, alignItems:`center`, gap:`1rem`}}>
            <div className='letter' style={{border:`1px solid #208001`}}>
              <div  style={{width:`100%`}}>
                  <h4 className='sous-titre1'>Nos contacts</h4>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-5 num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16" className='icon'>
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                </svg>
                <h4 className='contact'>&nbsp;&nbsp;&nbsp;&nbsp;(+221) 77 611 64 32</h4>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-5 num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16" className='icon'>
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                </svg>
                <h4 className='contact'>&nbsp;&nbsp;&nbsp;&nbsp;precvadithiadiaye@gmail.com</h4>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-5 num'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16" className='icon'>
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                </svg>
                <h4 className='contact'>&nbsp;&nbsp;&nbsp;&nbsp;Departement de Mbour</h4>
              </div>
            </div>
            <div className='letter'>
              <Newsletter />
            </div>
          </div>
        </div>
        <div style={{width:`100%`, display:`flex`, justifyContent:`center`, alignItems:`center`}}>
          <div className='spaced-div' style={{width:`91.5%`, display:`flex`, flexDirection:`column`, justifyContent:`center`, alignItems:`center`, gap:`1rem`}}>
            <div className="arrow1" style={{width:`100%`}}>
                <h1 className='gros-titre'>Nos partenaires</h1>
            </div>
            {/* <div style={{width:`50%`, height:`54.5vh`, border:`1px solid`}}>
              
            </div> */}
            <div className='spaced-div partenaire'>
              
              <Partenaires />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
